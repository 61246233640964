<template>
  <div class="home">
    <div style="overflow-x: hidden; padding: 0 10px">
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">发布日期：</span><span style="font-family: Calibri">202</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">年</span><span style="font-family: Calibri">0</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">5</span></span
                ><span style="font-family: 宋体; font-size: 14px">月</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">22</span></span
                ><span style="font-family: 宋体; font-size: 14px">日</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">用户隐私保护指引</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">为保障你的相关权利，《用户隐私保护指引》</span><span style="font-family: Calibri">(</span><span style="font-family: 宋体">下称“本隐私指引”</span><span style="font-family: Calibri">)</span><span style="font-family: 宋体">将向你说明</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">.</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">apk</span><span style="font-family: 宋体">应用会如何收集、使用和存储你的个人信息及你享有何种权利，请你在使用本</span><span style="font-family: Calibri">apk</span><span style="font-family: 宋体">应用之前，仔细阅读、了解并同意本隐私指引。本隐私指引适用于</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">.</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">apk</span><span style="font-family: 宋体">应用产品自身的功能及服务，不适用于其他产品或服务。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">本隐私指引将帮助你了解以下内容：</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">1.</span><span style="font-family: 宋体">接入第三方</span><span style="font-family: Calibri">SDK</span><span style="font-family: 宋体">服务</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.</span><span style="font-family: 宋体">我们收集用户信息的范围</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">3.</span><span style="font-family: 宋体">我们如何存储这些信息</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4.</span><span style="font-family: 宋体">我们如何保护这些信息</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">5.</span><span style="font-family: 宋体">我们使用收集到的用户信息的方式</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">6.</span><span style="font-family: 宋体">未成年人保护</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">7.</span><span style="font-family: 宋体">本隐私指引的变更</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">8.</span><span style="font-family: 宋体">联系我们</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务提供者：</span><span style="font-family: 宋体; font-size: 14px">北京乐悦互娱科技有限公司</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">(</span><span style="font-family: 宋体">以下简称“公司”</span><span style="font-family: Calibri">)</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户：指注册、登录、使用</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">应用产品及服务并获得管理权限的个人或组织，包括但不限于法人、政府机构、其他组织、合伙或个体工商户等</span><span style="font-family: Calibri">(</span><span style="font-family: 宋体">下称“</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">用户</span><span style="font-family: 宋体">”</span><span style="font-family: Calibri">);</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用管理员：指经</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用指定，拥有</span><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">管理后台系统操作权限的个人；</span><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">管理员可以一人或多人。</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px">个人用户：指被</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用申请加入</span><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">，注册使用</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">应用的个人用户，以下称</span><span style="font-family: 宋体">“你”或“最终用户”。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、通信记录和内容、财产信息、行踪轨迹、交易信息等。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">1.</span><span style="font-family: 宋体">接入第三方</span><span style="font-family: Calibri">SDK</span><span style="font-family: 宋体">服务</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">1.</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">1</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">微信支付</span><span style="font-family: Calibri">SDK</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">使用</span><span style="font-family: Calibri">SDK</span><span style="font-family: 宋体">名称：微信支付</span><span style="font-family: Calibri">SDK</span><span style="font-family: 宋体">服务类型；微信支付</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">,</span><span style="font-family: 宋体">微信登录</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">收集个人信息类型：设备信息</span><span style="font-family: Calibri">(IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/IP</span><span style="font-family: 宋体">地址</span><span style="font-family: Calibri">/SIM</span><span style="font-family: 宋体">卡</span><span style="font-family: Calibri">IMSI</span><span style="font-family: 宋体">信息等</span><span style="font-family: Calibri">)</span><span style="font-family: 宋体">隐私权政策链接：</span><span style="font-family: Calibri">https://www.tencent.com/zh-cn/privacy-policy.html</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">1.5 Alipay(</span><span style="font-family: 宋体">支付宝</span><span style="font-family: Calibri">)</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">使用</span><span style="font-family: Calibri">SDK</span><span style="font-family: 宋体">名称：</span><span style="font-family: Calibri">Alipay(</span><span style="font-family: 宋体">支付宝</span><span style="font-family: Calibri">)</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">服务类型：支付</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">收集个人信息类型：设备信息</span><span style="font-family: Calibri">(IMEI/MAC/AndroidID/IDFA/OpenUDID/GUID/IP</span><span style="font-family: 宋体">地址</span><span style="font-family: Calibri">/SIM</span><span style="font-family: 宋体">卡</span><span style="font-family: Calibri">IMSI</span><span style="font-family: 宋体">信息等</span><span style="font-family: Calibri">)</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px">隐私权政策链接：</span
                ><a href="https://opendocs.alipay.com/open/01g6qm#Android%20%C2%AOSDK"
                    ><span style="text-decoration: underline"
                        ><span style="font-family: 宋体; color: rgb(0, 0, 255)"><span style="font-family: Calibri">https://opendocs.alipay.com/open/01g6qm#Android%20%C2%AOSDK</span></span></span
                    ></a
                >
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.</span><span style="font-family: 宋体">我们收集哪些类型的信息</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px">为了向你和</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户提供服务、保障服务的正常运行、改进和优化我们的服务以及保障帐号安全，</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用会按照如下方式收集你在注册、使用服务时主动</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">提供、授权提供或因为使用服务而产生的信息：</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.1</span><span style="font-family: 宋体">帐号注册信息：当你首次注册登录</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用，你需要提供你的手机号、短信，上述信息为你使用</span><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">所必需，若你不提供这类信息，你将无法正常使用我们的服务。</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.2</span><span style="font-family: 宋体">当你使用</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务时，为向你及</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户提供</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用产品及服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障你的帐号安全，我们会收集你下述信息：</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.2.1</span><span style="font-family: 宋体">设备信息：根据你在使用</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务过程中的权限，我们会收集使用</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">应用服务的设备相关信息，包括</span><span style="font-family: Calibri">Android ID</span><span style="font-family: 宋体">、</span><span style="font-family: Calibri">IMEI</span><span style="font-family: 宋体">、设备</span><span style="font-family: Calibri">MAC</span><span style="font-family: 宋体">地址、设备型号、操作系统。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.2.2</span><span style="font-family: 宋体">日志信息：当你使用</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">应用服务时，我们会收集你对服务使用的日志信息，包括接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息</span><span style="font-family: Calibri">(</span><span style="font-family: 宋体">如你在</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">应用查看的网址信息、服务故障信息等</span><span style="font-family: Calibri">)</span><span style="font-family: 宋体">。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.2.3</span><span style="font-family: 宋体">你通过我们的客服或参加我们举办的活动时所提交的信息。例如，你参与我们线上活动时填写的调查问卷中可能包含你的姓名、电话等信息。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.3</span><span style="font-family: 宋体">为提供通讯服务，我们会收集</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">用户和</span><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">用户的最终用户使用</span><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">过程中提交或产生的信息和数据</span><span style="font-family: Calibri">(</span><span style="font-family: 宋体">下称“</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">控制数据</span><span style="font-family: Calibri">),</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">控制数据可能包括：</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.3.1</span><span style="font-family: 宋体">你的姓名、手机号码等用户提交或要求你提供的个人信息；你理解并同意，</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">应用用户为控制数据的控制者，我们仅根据用户的指示</span><span style="font-family: Calibri">(</span><span style="font-family: 宋体">包括</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">用户以及</span><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">用户管理员通过管理后台进行的操作等</span><span style="font-family: Calibri">)</span><span style="font-family: 宋体">以及我们与</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">用户间的协议进行相应的处理。如果你对以上应用控制数据的收集目的、范围和使用方式有任何疑问或意见，请联系客服处理。</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2.4</span></span
                ><span style="font-family: 宋体; font-size: 14px">恐龙思维</span><span style="font-family: 宋体; font-size: 14px">用户在上传和管理其最终姓名、手机号码等个人信息之前，请确保已经事先获得最终用户的明确同意，仅收集实现运营及功能目的所必需的最终用户信息，且已充</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">分告知最终用户相关数据收集的目的、范围及使用方式等。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">请你理解，我们向你提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且需要收集你的信息，我们会通过页面提示、交互流程、网站公告等方式另行</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">向你说明信息收集的内容、范围和目的，以征得你的同意。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">请你理解，我们向你提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且需要收集你的信息，我们会通过页面提示、交互流程、网站公告等方式另行</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">向你说明信息收集的内容、范围和目的，以征得你的同意。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">3.</span><span style="font-family: 宋体">我们如何存储这些信息</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">3.1</span><span style="font-family: 宋体">信息存储的地点</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">我们会按照法律法规规定，将在中国境内收集和产生的个人信息存储于中国境内。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">3.2</span><span style="font-family: 宋体">信息存储的期限</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px">一般而言，我们仅为实现目的所必需的时间保留你的个人信息。对于</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用可自主设定留存期限的部分</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">应用控制数据</span><span style="font-family: Calibri">(</span><span style="font-family: 宋体">如</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用的会员信息</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">文件图片等</span><span style="font-family: Calibri">),</span><span style="font-family: 宋体">我们会根据</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用的设置留存相关信息，我们不会查看或使用</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户留存的聊天记录和文件图片。</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知你，在合理的期限内删除你的个人信息或进行匿名化处理，并立即停止收集个人信息的活动</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4.</span><span style="font-family: 宋体">我们如何保护这些信息</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4.1</span><span style="font-family: 宋体">我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或披露。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4.2</span><span style="font-family: 宋体">我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用匿名化处理等手段来保护你的个人信息。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4.3</span><span style="font-family: 宋体">我们建立专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4.4</span><span style="font-family: 宋体">若发生个人信息泄露等安全事件，我们会依法启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知你安全事件的情况、事件可能对你的影响以及我们将采取的补救措施。我们还将按照法律法规和监管部门要求，上报个人信息安全事件的处置情况。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">5.</span><span style="font-family: 宋体">我们如何使用信息</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">我们严格遵守法律法规的规定及与用户的约定，将收集的信息按照本隐私保护指引的规定用于以下用途。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">5.1</span><span style="font-family: 宋体">我们在你使用</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务过程中收集相关的信息是为了向</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户打造和提供更好的服务。我们会将收集的信息用于以下用途：</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">5.1.1</span><span style="font-family: 宋体">提供、维护及开发</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务：我们会利用收集的信息来提供和优化改进</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务，例如，跟踪服务中断情况或排查</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户向我们报告的问题；根据</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用日志、服务使用信息等为你或你所在</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户提供各类数据统计分析功能及服务，如客户数据统计等。</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">5.1.2</span><span style="font-family: 宋体">安全保障：为保障你以及所有</span></span
                ><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用用户的安全，我们会利用相关信息协助提升</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务的安全性和可靠性，包括检测、防范和应对可能危害</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用、我们的用户或公众的欺诈行为、滥用行为、非法行为、安全风险和技术问题等；</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">6.</span><span style="font-family: 宋体">未成年人保护</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px">我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若你是未成年人，在使用</span><span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">恐龙思维</span><span style="font-family: Calibri">.apk</span></span
                ><span style="font-family: 宋体; font-size: 14px">应用服务前，应事先取得你的家长或法定监护人的同意。若你是未</span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">成年人的监护人，当你对你所监护的未成年人的个人信息有相关疑问时，请通过客服联系方式与我们联系。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">7.</span><span style="font-family: 宋体">本隐私指引的变更</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">我们可能会适时对本隐私指引进行修订。当指引的条款发生变更时，我们会通过公告、推送通知形式向你展示变更后的指引。当本隐私指引的条款发生重大变更时，我们将会通过应用公告、推送通知或者更为显著的弹窗方式向你告知。</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">本条所指的重大变更包括但不限于：</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">1)</span><span style="font-family: 宋体">我们的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">2)</span><span style="font-family: 宋体">我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有者变更等；</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">3)</span><span style="font-family: 宋体">个人信息共享、转让或公开披露的主要对象发生变化；</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">4)</span><span style="font-family: 宋体">你参与个人信息处理方面的权利及其行使方式发生重大变化；</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">5)</span><span style="font-family: 宋体">我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">6)</span><span style="font-family: 宋体">个人信息安全影响评估报告表明存在高风险时。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: Calibri">8.</span><span style="font-family: 宋体">与我们联系</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">当你有其他的投诉、建议、未成年人个人信息相关问题时，请通过联系下述方式：</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">开发者名称：</span><span style="font-family: 宋体; font-size: 14px">北京乐悦互娱科技有限公司，</span></p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">开发者联系邮箱：</span></span
                ><a href="mailto:3953090575@qq.com" style="font-family: 'Helvetica Neue'; font-size: 13px">3953090575@qq.com</a>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap">
                <span style="font-family: 宋体; font-size: 14px"><span style="font-family: 宋体">我们将尽快审核所涉问题，并在验证你的用户身份后的十五天内予以回复。</span></span>
            </p>
            <p style="text-align: justify; font-family: Calibri; font-size: 14px; text-wrap: wrap"><span style="font-family: 宋体; font-size: 14px">&nbsp;</span></p>
            <p><br /></p>
        </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPage'
};
</script>
