<template>
  <div class="home">
    <div style="overflow-x: hidden; padding: 0 10px">
            <p><strong>恐龙思维介绍</strong></p>
            <p><strong>公司介绍</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
            <p>杭州知行娱教科技有限公司是一家专注于儿童教育领域的企业，致力于为孩子们提供优质的教育资源和服务。我们的使命是通过创新的教育产品和服务，激发儿童学习的兴趣，培养其全面发展所需的技能和品质。 &nbsp; &nbsp;</p>
            <p>作为一家融合科技与教育的公司，我们关注最新的科技趋势，并将其运用到儿童教育中，以提供更具互动性和趣味性的学习体验。我们开发的教育游戏、应用程序和在线课程旨在结合教育和娱乐，让孩子们在玩耍的过程中不知不觉地学习知识和技能。 &nbsp;&nbsp;</p>
            <p>&nbsp;除了产品开发，我们还注重教育理念的传播和实践。我们组织各类儿童教育活动，为孩子们提供丰富多彩的学习体验；</p>
            <p>同时，我们也与学校和家长密切合作，共同关注孩子们的教育成长。 &nbsp; &nbsp;</p>
            <p>在未来，我们将继续不断创新，不断探索，为儿童教育领域带来更多的可能性。我们将秉承“以儿童为本、科技助力、创新驱动”的核心价值观，为孩子们的成长和发展贡献我们的力量，助力他们成为未来的领袖和创造者。</p>
            <p>
                <strong>软件介绍</strong>
            </p>
            <p>
                <strong>一、软件特性</strong>

                &nbsp; &nbsp;恐龙思维是一款高品质的训练思维软件，通过图文、语音、娱乐等方式锻炼儿童的逻辑思维能力，软件具有多种活动功能，充分锻炼儿童的思维能力，提高儿童的注意力、手脑能力。
            </p>
            <p>
                <strong>二、软件功能</strong>
            </p>
            <p>
                <strong>1、启动页面</strong>
                &nbsp; &nbsp;用户可以下载、安装和启动软件，进入软件首页。在主页上有两种主要的游戏模式，自由模式和每日关卡模式。
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>2、免费模式页面</strong>
                &nbsp; &nbsp;进入自由模式的主页面，用户可以查看锻炼自己思维能力的具体方法。用户可以点击相关模块的功能系统，根据孩子的实际需求学习相应的内容。 &nbsp; &nbsp;目前，按模块分类，有图形模块、数字模块、集合配对模块、数量比较模块、分类模块、逻辑推理模块、运算模块、空间模块、时间模块。 (1)图形模块 主要是帮助孩子建立对生活中常见图形的认知。
            </p>
            <p>&nbsp;</p>
            <p>(2)数概念模块 重点是帮助孩子认识数字0到20。</p>
            <p>&nbsp;</p>
            <p>(3)集合配对模块 主要是帮助孩子学习简单的物体匹配。</p>
            <p>&nbsp;</p>
            <p>(4)量比较模块 主要是帮助孩子树立生活中的数量观念和简单的数量比较。</p>
            <p>&nbsp;</p>
            <p>(5)分类排序模块 主要是为了帮助孩子们学会分类和排序。</p>
            <p>&nbsp;</p>
            <p>(6)逻辑推理模块 主要是帮助孩子建立推理和判断思维。</p>
            <p>&nbsp;</p>
            <p>(7)运算模块 主要是帮助孩子学习简单的数学运算。</p>
            <p>&nbsp;</p>
            <p>(8)空间模块 主要是帮助孩子理解生活中位置和空间的概念。</p>
            <p>&nbsp;</p>
            <p>(9)时间模块 主要是为了帮助孩子更好地理解时间周期和季节的概念。</p>
            <p>&nbsp;</p>
            <p>
                <strong>3、每日关卡模式页面</strong>
                &nbsp; &nbsp;在日常关卡模式的主页面，用户可以让孩子点击页面上的关卡按钮，进入关卡模式。只有通过上一关才能解锁下一关，这可以给孩子一种学习成就感。
            </p>
            <p>&nbsp;</p>
            <p>&nbsp; &nbsp;下面通过几个具体的游戏内容，说明如何通过游戏化的形式，来实现思维的锻炼。 (1)通过图形泡泡龙的形式，通过点击泡泡了解相应的图形，既有趣又潜移默化地加深幼儿对图形的认知。</p>
            <p>&nbsp;</p>
            <p>(2)通过数字蛇的形式，将人物拟物化形象生动地转化为蛇的食物。同时，游戏可以迅速使孩子对数字产生兴趣，使孩子对数字有一个基本的印象。</p>
            <p>&nbsp;</p>
            <p>(3)通过结合日常生活中可以看到的物体，锻炼幼儿将细节与整体相匹配的能力。</p>
            <p>&nbsp;</p>
            <p>
                <strong>4、家长验证</strong>
                &nbsp; &nbsp;因为我们的初衷是锻炼孩子的思维，虽然游戏化的形式可以吸引孩子的注意力，但也会造成一系列其他的问题，比如防止上瘾、保护视力、防止冲动消费等等。 因此，游戏时间过长或点击购买和设置按钮时出现家长验证页面。
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>5、家长设置</strong>
                &nbsp; &nbsp;这个页面允许你设置护眼模式时间，清除缓存，等等。
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>三、联系我们</strong>
                &nbsp; &nbsp;如果您有任何疑问，请通过以下方式与我们联系:3953090575@qq.com &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </p>
            <p>&nbsp;</p>
        </div>
  </div>
</template>

<script>
export default {
  name: 'OfficialWebPage'
};
</script>
